let resumeData = {
    "imagebaseurl":"https://github.com/jaj1658",
    "name": "Jean Jeon",
    "role": "",
    "linkedinId":"https://www.linkedin.com/in/jean-jeon/",
    "skypeid": "Your skypeid",
    "roleDescription": "I am an NYC-based front-end developer",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/jean-jeon/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/jaj1658",
          "className":"fa fa-github"
        },
      ],
    "aboutme":"",
    "address":"hi@jean-jeon.com",
    "website":"https://www.linkedin.com/in/jean-jeon/",
    "education":[
      {
        "UniversityName":"New York University",
        "specialization":"Computer Science",
        "MonthOfPassing":"May",
        "YearOfPassing":"2019",
      }
    ],
    "work":[
      {
        "CompanyName":"IMDb.com",
        "specialization":"Front End Engineer",
        "MonthOfLeaving":"08/2022 - ",
        "YearOfLeaving":"Present",
      },
      {
        "CompanyName":"Leadership Connect",
        "specialization":"Front End Engineer",
        "MonthOfLeaving":"01/2020 - ",
        "YearOfLeaving":"08/2022",
      }
    ]
  }
  
  export default resumeData